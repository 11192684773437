import Head from 'next/head';
import globalMeta from './globalmeta';
import { CldOgImage } from 'next-cloudinary';
const chars = {
  '/': '-',
  '"': '-',
  '§': '-',
  '!': '-',
  '*': '-',
  "'": '-',
  '´': '-',
  '{': '-',
  '}': '-',
  '\\': '-',
  '^': '-',
  '[': '-',
  ']': '-',
  '`': '-',
  ';': '-',
  '(': '-',
  ')': '-',
  '?': '-',
  ':': '-',
  '@': '-',
  // '&': '-',
  '=': '-',
  '+': '-',
  $: '-',
  ',': '%2c',
  '<': '-',
  '>': '-',
  '#': '-',
  '%': '-',
};
export default function SearchEngineHead({
  title = globalMeta.siteName,
  description,
  canonicalUrl = globalMeta.siteUrl,
  ogType = 'website',
  ogImgUrl = 'static/dasu14m5cj6eeeztmibt',
  structuredData,
  children,
  errorpage = false,
}) {
  return (
    <>
      <Head>
        {/*
        	Fundamental head elements.
    	*/}
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        <link rel="canonical" href={canonicalUrl} />
        <meta name="viewport" content="width=device-width, initial-scale=1 viewport-fit=cover" />
        <meta name="viewport" content="height=device-height, initial-scale=1 viewport-fit=cover" />
        <link rel="icon" sizes="32x32" href="/favicon.ico" />
        <link rel="icon" sizes="16x16" href="/favicon.ico" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon.ico" />
        {/*
        	Open graph meta tags.
    	*/}
        <meta property="og:title" content={title} />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:site_name" content={globalMeta.siteName} />
        <meta property="og:type" content={ogType} />
        {description && <meta property="og:description" content={description} />}
        <meta property="og:url" content={canonicalUrl} />

        {/*
        	Structured data.
        */}
        {/* <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: structuredData }} key="item-jsonld" /> */}
        {children}
      </Head>
      {!errorpage && (
        <CldOgImage
          src={ogImgUrl}
          width={2400}
          height={1200}
          crop="fill"
          alt={title}
          effects={[{ colorize: '60,co_black' }]}
          overlays={[
            {
              publicId: 'static/sghs3hxq9vpfivy9kxhu',
              position: {
                x: 60,
                y: 60,
                gravity: 'south_east',
              },
              appliedEffects: [
                {
                  multiply: true,
                  colorize: '100,co_white',
                  opacity: 60,
                },
              ],
              opacity: 60,
            },
            {
              crop: 'scale',
              width: '2000',
              text: {
                alignment: 'center',
                color: 'white',
                fontFamily: 'Source Sans Pro',
                fontSize: 90,
                fontWeight: 'bold',
                text: title ? title?.replace(/[#+"§!'´()*<>%,$+=@:?;\`\]\[\^\\}{/]/g, (m) => chars[m] || ' ') : '',
              },
              position: {
                y: -50,
              },
            },
            {
              crop: 'scale',
              width: '2000',
              text: {
                alignment: 'center',
                color: 'white',
                fontFamily: 'Source Sans Pro',
                fontSize: 65,
                text: description
                  ? description?.replace(/[#+"§!'´()*<>%,$+=@:?;\`\]\[\^\\}{/]/g, (m) => chars[m] || ' ')
                  : '',
              },
              position: {
                y: 50,
              },
            },
          ]}
          twitterTitle={title}
        />
      )}
    </>
  );
}
