import Image from 'next/image';
import { CldImage } from 'next-cloudinary';
import { MyLink } from '../MyLink';
import { Rating } from '@mui/material';
import { roundHalfWithPoint } from '../../utils';
import { useEffect, useState } from 'react';

export const LokaleLeistungenCard = ({
  homepage,
  ratings,
  listingTitle,
  description,
  pricing,
  studio,
  profile,
  images,
  category,
  differentLocation,
  link = '/lokale-leistungen',
}) => {
  const studioTypeChipStyle = {
    'Premium Studio': 'px-3 bg-white text-black w-max flex gap-2 flex flex-row gap-1 rounded-full py-1 px-3 ',
    'Medium Studio': 'px-3 bg-white text-black w-max flex gap-2 flex flex-row gap-1 rounded-full py-1 px-3 ',
    Heimstudio: 'px-3 bg-white text-black w-max flex gap-2 flex flex-row gap-1 rounded-full py-1 px-3 ',
  };

  return (
    <MyLink
      key={listingTitle}
      prefetch={false}
      href={link}
      rel="noopener nofollow"
      className={`relative mx-auto flex flex-col gap-2 rounded-xl p-0`}>
      <div className={`relative mx-auto flex aspect-square max-h-[250px]`}>
        {images.type !== 'thumbnail' ? (
          <CldImage
            objectFit="cover"
            width={700}
            height={700}
            src={images.publicID}
            alt={category.name + ' ' + 'Online Leistung'}
            className="max-w-full rounded-l-xl rounded-r-xl"
          />
        ) : (
          <Image
            objectFit="cover"
            width={700}
            height={700}
            src={'/images/Thumbnail-default.png'}
            alt={category.name + ' ' + 'Online Leistung'}
            className="max-w-full rounded-l-xl rounded-r-xl"
          />
        )}
        {/* chip */}
        <div
          className={`${
            studioTypeChipStyle[studio?.studiotype]
          } absolute left-3 top-4 z-10 text-sm font-semibold shadow-md`}>
          {studio?.studiotype}
          {studio?.studiotype === 'Premium Studio' && <img src={'/images/stars.svg'} alt="Premium Studio" />}
        </div>
      </div>
      <div className="flex w-full flex-col justify-between py-4 text-[15px]">
        <p
          className={`line-clamp-1 inline-block flex-nowrap items-center gap-0 truncate text-xs font-semibold text-black`}>
                {differentLocation?.status == "true"? differentLocation?.location?.city+", "+differentLocation?.location?.country : studio?.studioLocation ?
                 studio?.studioLocation.city+", "+studio?.studioLocation.country :
                profile?.location?.city+", "+profile?.location?.country
              }
        </p>
        <h3 className="line-clamp-2 font-semibold">{listingTitle}</h3>
        <p className="line-clamp-2 text-neutral-500">{description.replace(/(<([^>]+)>)/gi, '')}</p>
        <div className="flex items-center gap-5">
          {/* stars */}
          {ratings && (
            <div className="flex items-center">
              <Rating
                name="half-rating"
                size="small"
                sx={{
                  color: 'black',
                }}
                readOnly
                precision={1}
                value={1}
                emptyIcon={<></>}
              />
              <div className="text-base font-bold text-black">
                {roundHalfWithPoint(ratings?.stars)}
                <span className="font-normal text-gray-400">{` (${ratings?.count})`}</span>
              </div>
            </div>
          )}
          <div className="space-x-1 font-semibold text-black">
            <span>
              Ab{' '}
              {pricing?.pricingHour
                ? pricing?.pricingHour
                : pricing?.pricingDay
                ? pricing?.pricingDay
                : pricing?.pricingWeek
                ? pricing?.pricingWeek
                : pricing?.pricingMonth
                ? pricing?.pricingMonth
                : ''}{' '}
              €
            </span>
            {/* <span className="font-normal">•</span> */}
            <span className="font-normal">{category.name}</span>
          </div>
        </div>
      </div>
    </MyLink>
  );
};
