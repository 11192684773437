const globalMeta = {
  siteName: 'Esfaras',
  siteUrl: 'https://www.esfaras.de',
  siteLogo: 'https://www.esfaras.de/images/OpenGraph.png',
  email: 'allgemein@esfaras.de',
  description:
    'Finde das perfekte Tonstudio für einen super Preis überall in Deutschland. Mit Esfaras hast du den Überblick.',
};

export default globalMeta;
