import React from 'react';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TfiAngleLeft, TfiAngleRight, TfiLocationPin } from 'react-icons/tfi';

import 'swiper/css';
import { useRef } from 'react';
import { classNames, roundHalfWithPoint } from '../../utils';
import { LokaleLeistungenCard } from './LokaleLeistungenCard';
import { MyLink } from '../MyLink';
import Image from 'next/image';
import { CldImage } from 'next-cloudinary';
import { Rating, Tooltip, Zoom } from '@mui/material';
import { TooltipWrapper } from '../TooltipWrapper';

const LokaleLeistungenCarousel = ({ results, title, link, linkToDetailpage, homepage }) => {
  const swiperRef = useRef();
  const [swiperStartAndEnd, setSwiperStartAndEnd] = useState({
    isBeginning: true,
    isEnd: false,
  });
  const studioTypeChipStyle = {
    'Premium Studio': 'px-3 bg-white text-black w-max flex gap-2 flex flex-row gap-1 rounded-full py-1 px-3 ',
    'Medium Studio': 'px-3 bg-white text-black w-max flex gap-2 flex flex-row gap-1 rounded-full py-1 px-3 ',
    Heimstudio: 'px-3 bg-white text-black w-max flex gap-2 flex flex-row gap-1 rounded-full py-1 px-3 ',
    Musiker: 'px-3 bg-white text-black w-max flex gap-2 flex flex-row gap-1 rounded-full py-1 px-3 ',
    Tontechniker: 'px-3 bg-white text-black w-max flex gap-2 flex flex-row gap-1 rounded-full py-1 px-3 ',
  };

  return (
    <section className="relative z-50">
      <div className="ml-6 flex items-center pb-6 text-lg md:justify-between">
        <h2 className="text-2xl font-medium md:font-semibold">{title}</h2>
        <div
          className={classNames(homepage && '!hidden', 'hidden items-center justify-end gap-3 text-gray-800 md:flex')}>
          <button
            className="rounded-full border bg-white p-2.5 drop-shadow"
            onClick={() => swiperRef.current.slidePrev()}>
            <TfiAngleLeft
              className={classNames('h-3 w-3 stroke-1', swiperStartAndEnd.isBeginning && '!text-gray-300')}
            />
          </button>
          <button
            className="rounded-full border bg-white p-2.5 drop-shadow"
            onClick={() => swiperRef.current.slideNext()}>
            <TfiAngleRight className={classNames('h-3 w-3 stroke-1', swiperStartAndEnd.isEnd && '!text-gray-300')} />
          </button>
        </div>
      </div>
      <div
        className={classNames(
          !homepage && '!hidden',
          'grid gap-5 px-5 max-lg:grid-cols-2 max-sm:grid-cols-1 lg:grid-cols-12'
        )}>
        {results?.map(
          ({ _id, listingTitle, user, description, pricing, studio, profile, images, service, differentLocation, ratings, i }) => (
            <div key={listingTitle} className={i < 3 ? 'md:block lg:col-span-3' : 'md:block lg:col-span-3'}>
              <MyLink
                prefetch={false}
                href={`/lokale-leistungen/${_id}`}
                rel="noopener nofollow"
                target="_blank"
                key={_id}
                className={`min-h-[400px]} relative h-fit max-h-[550px]`}>
                <div className="flex w-full flex-col justify-between px-2 lg:px-0">
                  {/* image */}
                  <div className="relative aspect-video w-full">
                    {images?.primary?.type === 'thumbnail' ? (
                      <Image
                        className="right-0 h-full w-full rounded-2xl object-cover"
                        width={650}
                        height={400}
                        objectFit="cover"
                        sizes="(min-width: 1024px) 30rem, 25rem"
                        quality={50}
                        src={'/images/Thumbnail-default.png'}
                        alt="Tonstudio service bild"
                        // loading="eager"
                      />
                    ) : (
                      <CldImage
                        className="right-0 h-full w-full rounded-2xl object-cover"
                        width={650}
                        height={400}
                        objectFit="cover"
                        sizes="(min-width: 1024px) 30rem, 25rem"
                        quality={'auto'}
                        src={images.primary.publicID}
                        alt="Tonstudio service bild"
                        // loading="eager"
                      />
                    )}
                    {/* old design */}
                    {/* chip */}
                    <div
                      className={`${
                        studioTypeChipStyle[studio?.studiotype || profile?.role?.name]
                      } absolute left-3 top-4 z-10 text-xs font-semibold uppercase`}>
                      {studio?.studiotype ? studio.studiotype : profile?.role?.name}
                      {studio?.studiotype === 'Premium Studio' && (
                        <img src={'/images/stars.svg'} alt="Premium Studio" />
                      )}
                    </div>
                    {/* heart favourites feature */}
                    {/* z-10 because hearticon in dashboard bookmarks page is infront of header, also in showherosearch button modals */}

                    {user?.userProviderSubscriptionId ? (
                      <div className="absolute bottom-4 left-4 z-[10] lg:block">
                        <Tooltip
                          title={<p className="whitespace-nowrap text-sm">Verifizierter Anbieter</p>}
                          placement={'top'}
                          className="text-xl"
                          arrow
                          enterTouchDelay={0}
                          leaveTouchDelay={2500}
                          TransitionComponent={Zoom}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                display: { xs: 'none', sm: 'block' },
                                bgcolor: 'grey.800',
                                '& .MuiTooltip-arrow': {
                                  color: 'grey.800',
                                },
                              },
                            },
                          }}
                          PopperProps={{
                            disablePortal: true,
                            popperOptions: {
                              positionFixed: true,
                              modifiers: {
                                preventoverflow: {
                                  enabled: true,
                                  boundarieselement: containerRef, // where "window" is the boundary
                                },
                              },
                            },
                          }}>
                          <TooltipWrapper className="ml-1">
                            <span className="me-2 inline-flex h-8 w-8 items-center justify-center rounded-full bg-white text-sm font-semibold text-green-600">
                              <svg
                                className="h-6 w-6"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20">
                                <path
                                  fill="currentColor"
                                  d="m18.774 8.245-.892-.893a1.5 1.5 0 0 1-.437-1.052V5.036a2.484 2.484 0 0 0-2.48-2.48H13.7a1.5 1.5 0 0 1-1.052-.438l-.893-.892a2.484 2.484 0 0 0-3.51 0l-.893.892a1.5 1.5 0 0 1-1.052.437H5.036a2.484 2.484 0 0 0-2.48 2.481V6.3a1.5 1.5 0 0 1-.438 1.052l-.892.893a2.484 2.484 0 0 0 0 3.51l.892.893a1.5 1.5 0 0 1 .437 1.052v1.264a2.484 2.484 0 0 0 2.481 2.481H6.3a1.5 1.5 0 0 1 1.052.437l.893.892a2.484 2.484 0 0 0 3.51 0l.893-.892a1.5 1.5 0 0 1 1.052-.437h1.264a2.484 2.484 0 0 0 2.481-2.48V13.7a1.5 1.5 0 0 1 .437-1.052l.892-.893a2.484 2.484 0 0 0 0-3.51Z"
                                />
                                <path
                                  fill="#fff"
                                  d="M8 13a1 1 0 0 1-.707-.293l-2-2a1 1 0 1 1 1.414-1.414l1.42 1.42 5.318-3.545a1 1 0 0 1 1.11 1.664l-6 4A1 1 0 0 1 8 13Z"
                                />
                              </svg>
                              <span className="sr-only">Verifizierungsabzeichen</span>
                            </span>
                          </TooltipWrapper>
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>
                  {/* below img */}
                  <div className="group cursor-pointer">
                    {/* title */}
                    {/* address */}
                    <div className="mt-4 flex justify-between">
                      <div className="flex flex-row items-start gap-1 text-sm">
                        <TfiLocationPin className="mt-[0.1rem] shrink-0 text-gray-500" />
                        <span className="line-clamp-1 text-gray-500">
                        {differentLocation?.status == "true"? differentLocation?.location?.postalcode +" "+ differentLocation?.location?.city+", "+differentLocation?.location?.state : studio?.studioLocation ?
                          studio?.studioLocation.postalcode +" "+ studio?.studioLocation.city+", "+studio?.studioLocation.state :
                          profile?.location?.postalcode+" "+profile?.location?.city+", "+profile?.location?.state
                        }
                        </span>
                      </div>
                    </div>
                    <h1 className={`mb-2 line-clamp-2 text-xl font-semibold`}>{listingTitle}</h1>
                    {/* location features */}
                    <div className="flex flex-row flex-wrap gap-2 py-1.5 text-xs text-zinc-400">
                      {studio?._id ? (
                        studio?.locationFeatures?.map((ft, index) => (
                          <div
                            key={index}
                            className="w-max rounded-xl border border-gray-300 bg-gray-200 px-3 font-extralight text-black">
                            <p>{ft}</p>
                          </div>
                        ))
                      ) : (
                        <>
                          {profile?.instruments?.slice(0, 5)?.map((ft, index) => (
                            <div
                              key={index}
                              className="w-max rounded-xl border border-gray-300 bg-gray-200 px-3 font-extralight text-black">
                              <p>{ft}</p>
                            </div>
                          ))}
                          {profile?.genres?.slice(0, 5)?.map((ft, index) => (
                            <div
                              key={index}
                              className="w-max rounded-xl border border-gray-300 bg-gray-200 px-3 font-extralight text-black">
                              <p>{ft}</p>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                    {/* last row */}
                    <div className="flex flex-row items-center justify-between pt-2">
                      {/* service name and workinghours */}
                      <div className="flex flex-col text-sm">
                        {/* servicename */}
                        <span>{service.name}</span>
                        {/* stars */}
                        {ratings && (
                          <div className="flex items-center">
                            <Rating
                              name="half-rating"
                              size="small"
                              sx={{
                                color: 'black',
                              }}
                              readOnly
                              precision={1}
                              value={1}
                              emptyIcon={<></>}
                            />
                            <div className="text-sm font-bold text-black">
                              {ratings?.stars ? roundHalfWithPoint(ratings?.stars / ratings?.count) : 0}
                              <span className="font-normal text-gray-400">{` (${ratings?.count})`}</span>
                            </div>
                          </div>
                        )}
                        {/* hours
              <div>
                {workingHours.status === 'custom' ? (
                  <div>
                    <p title="Öffnungszeiten" className={`col-span-1 text-gray-500 md:col-span-2`}>
                      Individuell
                    </p>
                  </div>
                ) : workingHours.status === '24 / 7' ? (
                  <div>
                    <p title="Öffnungszeiten" className={`col-span-1 text-gray-500 md:col-span-2`}>
                      Immer Verfügbar
                    </p>
                  </div>
                ) : workingHours.status === 'on request' ? (
                  <div>
                    <p title="Öffnungszeiten" className={`col-span-1 text-gray-500 md:col-span-2`}>
                      Auf Anfrage
                    </p>
                  </div>
                ) : null}
              </div> */}
                      </div>
                      {/* price btn*/}
                      <button
                        className={`flex items-center gap-2 rounded-lg  px-5 py-1.5 text-sm font-semibold md:text-base`}>
                        <span className="w-max">
                          {pricing?.pricingHour ? (
                            <>
                              {pricing?.pricingHour}€ <span>/ Stunde</span>
                            </>
                          ) : pricing?.pricingDay ? (
                            <>
                              {pricing?.pricingDay}€ <span>/ Tag</span>
                            </>
                          ) : pricing?.pricingWeek ? (
                            <>
                              {pricing?.pricingWeek}€ <span>/ Woche</span>
                            </>
                          ) : pricing?.pricingMonth ? (
                            <>
                              {pricing?.pricingMonth}€ <span>/ Monat</span>
                            </>
                          ) : null}
                        </span>
                        {/* <TfiArrowRight /> */}
                      </button>
                    </div>
                  </div>
                </div>
              </MyLink>
            </div>
          )
        )}
      </div>
      <div className={classNames(homepage && '!hidden')}>
        <Swiper
          breakpoints={{
            300: {
              slidesPerView: 1.5,
              spaceBetween: 25,
            },
            742: {
              slidesPerView: 3,
              spaceBetween: 20,
              slidesPerGroup: 3,
            },
            1128: {
              slidesPerView: 4,
              spaceBetween: 20,
              slidesPerGroup: 4,
            },
            1920: {
              slidesPerView: 4,
              spaceBetween: 20,
              slidesPerGroup: 4,
            },
            2400: {
              slidesPerView: 6,
              spaceBetween: 25,
              slidesPerGroup: 6,
            },
          }}
          onSlideChange={(swiper) => {
            setSwiperStartAndEnd({
              isBeginning: swiper.isBeginning,
              isEnd: swiper.isEnd,
            });
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onReachEnd={(swiper) => {
            setSwiperStartAndEnd({
              isBeginning: swiper.isBeginning,
              isEnd: swiper.isEnd,
            });
          }}>
          {results?.map(({ _id, listingTitle, description, pricing, studio, images, profile, service, differentLocation }) => (
            <SwiperSlide key={_id}>
              <LokaleLeistungenCard
                link={linkToDetailpage ? `/lokale-leistungen/${_id}` : link}
                listingTitle={listingTitle}
                description={description}
                pricing={pricing}
                images={images.primary}
                studio={studio}
                profile={profile}
                category={service}
                differentLocation={differentLocation}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default LokaleLeistungenCarousel;
