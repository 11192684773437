import Image from 'next/image';
import { CldImage } from 'next-cloudinary';
import { MyLink } from '../MyLink';

export const OnlineLeistungenCard = ({ _id, description, images, packages, category, title, link }) => {
  return (
    <MyLink
      prefetch={false}
      href={link ? link : '/online-leistungen'}
      rel="noopener nofollow"
      className={`relative mx-auto mb-5 flex flex-col gap-2 rounded-xl p-0`}>
      <div className={`relative mx-auto flex aspect-square max-h-[250px]`}>
        {images.type !== 'thumbnail' ? (
          <CldImage
            objectFit="cover"
            width={700}
            height={700}
            src={images.publicID}
            alt={category.name + ' ' + 'Online Leistung'}
            priority={true}
            className="max-w-full rounded-l-xl rounded-r-xl"
          />
        ) : (
          <Image
            objectFit="cover"
            width={700}
            height={700}
            src={'/images/Thumbnail-default.png'}
            alt={category.name + ' ' + 'Online Leistung'}
            priority={true}
            className="max-w-full rounded-l-xl rounded-r-xl"
          />
        )}
      </div>
      <div className="flex w-full flex-col justify-between py-4 text-[15px]">
        <p
          className={`line-clamp-1 inline-block flex-nowrap items-center gap-0 truncate text-xs font-semibold text-black`}>
          {category.name}
        </p>
        <h3 className="font-semibold">{title}</h3>
        <div className="line-clamp-2 text-neutral-500">{description.replace(/(<([^>]+)>)/gi, '')}</div>
        <div className="font-semibold text-black">Ab {packages.price} €</div>
      </div>
    </MyLink>
  );
};
